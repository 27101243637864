.upload-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}
.img-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  padding: 2rem;
  width: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.img-upload p {
  text-align: left;
}
.img-upload input {
  width: 20rem;
  height: 2.6rem;
  margin: 5px;
  border: 1px solid rgb(154, 154, 154);
  padding: 5px;
  font-size: 1rem;
}

.img-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.img-details button,
.edit-btn button {
  background-image: linear-gradient(
    to right,
    #02aab0 0%,
    #00cdac 51%,
    #02aab0 100%
  );
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border: #00cdac;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  font-weight: 600;
}

.img-details button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.edit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  padding: 2rem;
  width: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.upload-form-single input {
  width: 25rem;
  margin: 5px 0;
  height: 3rem;
  border-radius: 5px;
  border: 1px solid rgb(154, 154, 154);
  padding: 5px 10px;
  font-size: 1rem;
}
.upload-form-single p {
  text-align: left;
}

.upload-btn button {
  background-image: linear-gradient(
    to right,
    #1fa2ff 0%,
    #12d8fa 51%,
    #1fa2ff 100%
  );
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  border: #1fa2ff;
  font-weight: 600;
}
.upload-btn button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.fetch-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.edit-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 1rem;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.delete-img {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.delete-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding: 5rem 1rem;
  width: 90%;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.edit-img-upload {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  padding: 2rem;
  /* width: 50%;  */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.edit-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.delete-btn button {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  border: #dd2476;
  cursor: pointer;
}
.delete-btn button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

/* Login page */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(
    -225deg,
    #22e1ff 0%,
    #1d8fe1 48%,
    #625eb1 100%
  );
  height: 100vh;
  width: 100%;
}
.login-page p {
  color: #eee;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
}
.login-page form {
  background-color: #fff;
  padding: 3rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.login-page form input {
  height: 2rem;
  width: 15rem;
  margin-bottom: 1rem;
  border: 2px solid rgb(205, 205, 205);
  border-radius: 25px;
  padding: 5px 5px 5px 10px;
}

.login-page button {
  background-image: linear-gradient(
    to right,
    #0575e6 0%,
    #021b79 51%,
    #0575e6 100%
  );
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  border: #021b79;
  cursor: pointer;
}

.login-page button:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
/* Admin Home page */
.admin-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 5rem;
  background-image: linear-gradient(
    to top,
    #1e3c72 0%,
    #1e3c72 1%,
    #2a5298 100%
  );
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  gap: 10px;
}
.admin-nav p {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.admin-nav button {
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: 500;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border: 1px solid white;
  border-radius: 2px;
  cursor: pointer;
  color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

/* Admin Home */
.admin-home-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.member-box {
  /* background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); */
  margin: 2rem;
  padding: 1rem;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.member-card{
  background-color: #fff;
  margin: 1rem;
}

.admin-home-bg {
  /* background-color: #00cdac; */ 
   /* background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); */
  background-image: linear-gradient(135deg, #f5f7fa 0%, #b7b9bd 100%);
}
.member-box p {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.member-box img {
  height: 100px;
  width: 100px;
  margin-bottom: 1rem;
}

.user-id h3 p {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  border: 1.5px solid rgb(228, 228, 228);
  padding: 5px;
}

.user-id h3 p span {
  background-color: rgb(223, 223, 223);
  font-size: 1.5rem;
  padding: 5px;
  cursor: pointer;
}

.user-id h3 {
  font-size: 1rem;
  font-weight: 500;
}

/* Mobile devices  */
@media only screen and (max-width: 520px) {
  .edit-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .edit-wrapper {
    width: 90%;
    padding: 10px;
  }
  .delete-img {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 5rem;
  }

  .upload-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .img-upload {
    width: 90%;
  }
  .upload-form {
    width: 90%;
    margin-bottom: 5rem;
  }
  .admin-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    flex-wrap: wrap;
    font-size: 1rem;
    font-weight: 500;
    gap: 12px;
  }

  .login-page {
    display: flex;
    justify-content: flex-start;
  }
  .login-page p {
    color: #eee;
    font-size: 2rem;
    font-weight: 600;
    text-transform: capitalize;
    margin: 5rem 0 2rem 0;

  }

  
  .login-page form input {
    height: 3rem;
    width: 15rem;
    margin-bottom: 1rem;
    border: 2px solid rgb(160, 160, 160);
    border-radius: 25px;
    padding: 5px 5px 5px 10px;
  }
  .upload-form-single input {
    width: 18rem;
    margin: 5px 0;
    height: 3rem;
    border-radius: 5px;
    border: 1px solid rgb(154, 154, 154);
    padding: 5px 10px;
    font-size: 1rem;
  }

  .upload-form-single .textarea {
    display: none !important;
  }
  .admin-home-bg{
    
  }
  .member-card{
     margin: 1rem;
     /* border: 2px 2px 10px 2px solid rgb(194, 194, 194); */
     background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  }
}
