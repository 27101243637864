.f3 {
  height: 700px;
  max-height: calc(100vh - 0px);
  /* width: 900px; */
  cursor: grab;
  max-width: 100%;
  margin: auto;
  position: relative;
}
#FamilyChart image{
  /* opacity: 0.3; */
  
}
.f3 svg.main_svg text tspan:nth-child(2){
  font-size: 12px;
  font-weight: 500;
}
path.link { stroke: #000; }
.f3 .cursor-pointer {
  cursor: pointer;
}
.f3 svg.main_svg {
  width: 100%;
  height: 100%;
  /* background-color: #ffffff; */
  
  /* background-color: #ffffff; */
/* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23b29ad9' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); */

color: #000000;
  font-size: 20px;
}
.f3 svg.main_svg text {
  fill: currentColor;
  font-size: 20px;
}

.f3 rect.card-female,
.f3 .card-female .card-body-rect,
.f3 .card-female .text-overflow-mask {
  fill: rgb(250, 158, 158);
}
.f3 rect.card-male,
.f3 .card-male .card-body-rect,
.f3 .card-male .text-overflow-mask {
  fill: rgb(98, 212, 250);
}
.f3 .card-genderless .card-body-rect,
.f3 .card-genderless .text-overflow-mask {
  fill: lightgray;
}
.f3 .card_add .card-body-rect {
  fill: #3b5560;
  stroke-width: 4px;
  stroke: #fff;
  cursor: pointer;
  border-radius: 50%;
}
.f3 g.card_add text {
  fill: rgb(0, 0, 0);
}
.f3 .card-main {
  stroke: #000;
}
.f3 .card_family_tree rect {
  transition: 0.3s;
}
.f3 .card_family_tree:hover rect {
  transform: scale(1.1);
}
.f3 .card_add_relative {
  cursor: pointer;
  color: #fff;
  transition: 0.3s;
}
.f3 .card_add_relative circle {
  fill: rgba(0, 0, 0, 0);
}
.f3 .card_add_relative:hover {
  color: black;
}
.f3 .card_edit.pencil_icon {
  color: #fff;
  transition: 0.3s;
}
.f3 .card_edit.pencil_icon:hover {
  color: black;
}
.f3 .card_break_link,
.f3 .link_upper,
.f3 .link_lower,
.f3 .link_particles {
  transform-origin: 50% 50%;
  transition: 1s;
}
.f3 .card_break_link {
  color: #fff;
}
.f3 .card_break_link.closed .link_upper {
  transform: translate(-140.5px, 655.6px);
}
.f3 .card_break_link.closed .link_upper g {
  transform: rotate(-58deg);
}
.f3 .card_break_link.closed .link_particles {
  transform: scale(0);
}
.f3 .input-field input {
  height: 2.5rem !important;
}
.f3 .input-field > label:not(.label-icon).active {
  -webkit-transform: translateY(-8px) scale(0.8);
  transform: translateY(-8px) scale(0.8);
}
