.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    border-bottom: 0.1px solid rgb(202, 202, 202);
    color: #000;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  
  .navbar_links {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
  }
  
  .navbar_links_logo {
    margin-right: 3rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
  
  .navbar_links_logo {
    font-size: 1rem;
    color: #000;
    font-weight: 500;
  }
  
  .navbar_links_container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-right: 2rem;
  }
  
  .navbar_sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .links_container p,
  .navbar_sign p,
  .navbar_menu_container p {
    color: #fff;
    font-family: var(--font-exo);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
  
    margin: 0 1rem;
    cursor: pointer;
  }
  
  .navbar_sign button,
  .navbar_menu_container button {
    padding: 0.4rem 1.5rem;
    color: #fff;
    font-weight: 400;
    font-size: 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 2px;
    border: 1.28px double transparent;
    background-image: linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)),
      radial-gradient(circle at left top, rgb(0, 134, 252), rgb(253, 0, 181));
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  
  .navbar_menu {
    margin-left: 1rem;
  
    display: none;
    /* position: relative; */
  }
  
  .navbar_menu svg {
    cursor: pointer;
  }
  
  .navbar_menu_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
    padding: 2rem 1.2rem;
    position: absolute;
    right: 2rem;
    z-index: 10;
    margin-top: 4rem;
    min-width: 18rem;
    border-image-slice: 1;
    border-width: 1.5px;
    border-radius: 10px;
  
    background: radial-gradient(
      circle at 3% 25%,
      rgba(0, 40, 83, 0.619) 0%,
      rgba(4, 12, 24, 0.55) 25%
    );
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
  
  .border_gradient {
    border-image-source: linear-gradient(50deg, #00c0f5, #002293);
  }
  
  .navbar_menu_container p {
    margin: 1rem 0;
  }
  
  .navbar_menu_container_links_sign {
    display: none;
  }
  
  @media screen and (max-width: 1050px) {
    .navbar_links_container {
      display: none;
    }
  
    .navbar_menu {
      display: flex;
      color: #000;
    }
  }
  
  @media screen and (max-width: 700px) {
    .navbar {
      padding: 2rem 4rem;
    }
  }
  
  @media screen and (max-width: 550px) {
    .navbar {
      padding: 1.5rem 1rem;
    }
  
    .navbar_sign {
      display: none;
    }
  
    .navbar_menu_container {
      top: 20px;
    }
  
    .navbar_menu_container_links_sign {
      display: block;
    }
    .navbar_menu_container_links_sign button {
      padding: 0.5rem 2rem;
      color: #fff;
      font-weight: 500;
      font-size: 1rem;
      cursor: pointer;
      border-radius: 5px;
      border: 1.8px double transparent;
      background-image: linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)),
        radial-gradient(circle at left top, rgb(0, 134, 252), rgb(253, 0, 181));
      background-origin: border-box;
      background-clip: padding-box, border-box;
    }

    .navbar_links_logo {
      margin-right: 0rem;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
    }
  }