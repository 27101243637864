@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
body{
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.bgTree{
  /* height: 100vh; */
  background-image: url('././images/bg.jpg');
    
background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
.helpButton{
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2rem;
  background-color: #ff164d;
  cursor: pointer;
}

.popup{
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* transition: all 0.7s linear; */
  z-index: 2;
  background-image: url('././images/bg.jpg');

}
.popupClose{
  position: absolute;
  top: 5%;
  right: 10%;
  font-size:2rem;
  cursor: pointer;
}
.popupData{
  display: flex;
  justify-content: flex-start;
  text-align: left;
  padding: 2rem;
}

.popupData ul li{
  list-style-type: square;
}
.memberCardWrapper{
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.memberCard{
  height: 25rem;
  position: relative;
  width: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.memberCard button{
  position: absolute;
  top: 5%;
  right: 5%;
  font-size:2rem;
  color: #ff1c1c;
  cursor: pointer;
}

.comingSoon, .contact{
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url('././images/bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.comingSoon h1{
  font-size: 2rem;
  font-weight: 500;
  color: #4c0000;
}
.contact h2{
  font-size: 1rem;
  font-weight: 500;
  color: #4c0000;
  width: 70%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media  (max-width:480px) {
  .memberCard{
    height: 30rem;
    position: relative;
    width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  } 
}
